import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormControl } from '@material-ui/core'
import { getDAM3ContingencyBranchNames } from '../../../api'
import { SearchableSelect } from '../../../SearchableSelect/SearchableSelect'

const useStyles = makeStyles((theme) => ({
    formControl: {
        display: 'flex',
    },
}))

const DAMPostProcessingContingencyBranchesWidget = (props) => {
    const { onChange, formContext, id } = props
    const [branchNames, setBranchNames] = useState([])
    const [branchName, setBranchName] = useState('')

    // Extract index from id (e.g., "root_run_version_0_contingency" -> 0)
    const runVersionIndex = parseInt(id.split('_')[3], 10)

    const classes = useStyles()

    const handleChange = (event) => {
        let cont = event.target.value
        setBranchName(cont)
        onChange(cont)
    }

    useEffect(() => {
        const asyncFetchGetDAM3BranchNames = async (
            DAM3PipelineID,
            ercotDate,
            ercotHour,
            contingencyName
        ) => {
            if (
                DAM3PipelineID !== null &&
                DAM3PipelineID !== undefined &&
                ercotDate !== null &&
                ercotDate !== undefined &&
                ercotHour !== null &&
                ercotHour !== undefined &&
                contingencyName !== null &&
                contingencyName !== undefined
            ) {
                const res = await getDAM3ContingencyBranchNames(
                    DAM3PipelineID,
                    ercotDate,
                    ercotHour,
                    contingencyName
                )
                if (res.data !== null) {
                    // Transform the array of strings into an array of objects,
                    // to be able to use searchable select
                    setBranchNames(
                        res.data.map((s) => ({
                            label: s,
                            value: s,
                        }))
                    )
                }
            } else {
                setBranchNames([])
            }
        }

        if (
            formContext &&
            formContext.pipeline_settings &&
            formContext.run_version[runVersionIndex] &&
            formContext.run_version[runVersionIndex].modelDateDAM &&
            formContext.run_version[runVersionIndex].modelHourDAM &&
            formContext.run_version[runVersionIndex].contingency
        ) {
            asyncFetchGetDAM3BranchNames(
                formContext.pipeline_settings.DAM3PipelineID,
                formContext.run_version[runVersionIndex].modelDateDAM,
                formContext.run_version[runVersionIndex].modelHourDAM,
                formContext.run_version[runVersionIndex].contingency
            )
        }
    }, [formContext, runVersionIndex])

    return (
        <div>
            {branchNames.length >= 1 ? (
                <FormControl className={classes.formControl}>
                    <SearchableSelect
                        key={branchName}
                        label="Branch name"
                        value={branchName}
                        onChange={handleChange}
                        options={branchNames}
                        keyPropFn={(option) => option.value}
                        valuePropFn={(option) => option.value}
                        showAll
                        removeSelectionText
                        alwaysFocusTextFieldOnEnter
                        formControlProps={{ fullWidth: true }}
                    />
                    <br />
                    <br />
                </FormControl>
            ) : (
                <div>
                    <br />
                    Enter DAM 3 ID, Ercot Date, Hour, and contingency to show
                    available branches
                    <br />
                    <br />
                </div>
            )}
        </div>
    )
}
export default DAMPostProcessingContingencyBranchesWidget
