import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { getDAM3ScenariosByPipeline } from '../../../api'

const useStyles = makeStyles((theme) => ({
    formControl: {
        display: 'flex',
    },
}))

const DAMPostProcessingSingleScenarioWidget = (props) => {
    const { onChange, formContext } = props
    const [scenarios, setScenarios] = useState([])

    const [scenario, setScenario] = useState('')

    const classes = useStyles()

    const handleChange = (event) => {
        let scen = event.target.value
        setScenario(Number(scen))
        onChange(Number(scen))
    }

    useEffect(() => {
        const asyncFetchGetDAM3Scenarios = async (DAM3PipelineID) => {
            if (DAM3PipelineID !== null && DAM3PipelineID !== undefined) {
                const res = await getDAM3ScenariosByPipeline(DAM3PipelineID)
                if (res.data !== null) {
                    setScenarios(res.data)
                }
            } else {
                setScenarios([])
            }
        }
        if (
            formContext &&
            formContext.pipeline_settings &&
            formContext.pipeline_settings.DAM3PipelineID
        ) {
            asyncFetchGetDAM3Scenarios(
                formContext.pipeline_settings.DAM3PipelineID
            )
        }
    }, [formContext, formContext?.pipeline_settings?.DAM3PipelineID])

    return (
        <div>
            {scenarios.length >= 1 ? (
                <FormControl className={classes.formControl}>
                    <InputLabel id="scenario-select-label">
                        DAM 3 Scenario
                    </InputLabel>
                    <Select
                        labelId="scenario-select-label"
                        id="scenario-select"
                        value={scenario || ''}
                        onChange={handleChange}
                    >
                        {scenarios.map((scen) => (
                            <MenuItem value={scen} key={scen}>
                                {scen}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            ) : (
                <div>Enter a DAM 3 ID to show available scenarios</div>
            )}
        </div>
    )
}
export default DAMPostProcessingSingleScenarioWidget
