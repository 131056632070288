import { Worker, Viewer } from '@react-pdf-viewer/core'
import { toolbarPlugin } from '@react-pdf-viewer/toolbar'
import { SelectionMode } from '@react-pdf-viewer/selection-mode'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/toolbar/lib/styles/index.css'

const transformTB = (slot) => ({
    ...slot,
    // These slots will be empty
    Open: () => <></>,
    Print: () => <></>,
    ShowPropertiesMenuItem: () => <></>,
    EnterFullScreen: () => <></>,
    SwitchTheme: () => <></>,
})

const PDFViewerComponent = (props) => {
    // Create plugin instances
    const toolbarPluginInstance = toolbarPlugin({
        getFilePlugin: {
            fileNameGenerator: (file) => {
                return `Network Comparison Graph - ${props.visualization.subtitle}`
            },
        },
        selectionModePlugin: {
            selectionMode: SelectionMode.Hand,
        },
    })
    const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance
    return (
        <div style={{ width: '100%', overflow: 'auto' }}>
            <Toolbar>{renderDefaultToolbar(transformTB)}</Toolbar>
            <Worker
                workerUrl={`https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js`}
            >
                <Viewer
                    fileUrl={`data:application/pdf;base64,${props.visualization.base64_data}`}
                    renderTextLayer={false}
                    plugins={[toolbarPluginInstance]}
                />
            </Worker>
            <i> &emsp;&emsp;&emsp; {props.visualization.subtitle}</i>
        </div>
    )
}

export default PDFViewerComponent
