import React from 'react'
import Chart from 'react-apexcharts'
import MiMAnalysisByPath from './CustomTaskCharts/MiMAnalysisByPath'
import MiMRawDamAnalysis from './CustomTaskCharts/MiMRawDamAnalysis'
import MiMRawCRRAnalysis from './CustomTaskCharts/MiMRawCRRAnalysis'
import DataTable from './CustomTaskCharts/DataTable'
import DAM3FlowDecompositionDriver from './CustomTaskCharts/DAM3FlowDecompositionDriver'
import DAM3FDOnDemandStarter from './CustomTaskCharts/DAM3FDOnDemandStarter'
import moment from 'moment'

const TaskChart = (props) => {
    const { data, type } = props.visualization
    const { tooltip } = props

    function getChart() {
        const xAxisCategories =
            data.x_axis_type === 'datetime'
                ? data.x_axis_categories.map((dateStr) =>
                      new Date(dateStr).getTime()
                  ) // Convert to timestamps
                : data.x_axis_categories

        const CHART_DEFAULTS = {
            pie: {
                options: {
                    colors: [
                        '#ff5261',
                        '#d50037',
                        '#9b0012',
                        '#85545a',
                        '#572a31',
                        '#2c0108',
                    ],
                    title: {
                        text: data.title || '',
                        align: 'left',
                    },
                    labels: data.labels,
                },
                type: 'pie',
            },
            stacked_bar: {
                options: {
                    colors: [
                        '#ff5261',
                        '#2c0108',
                        '#d50037',
                        '#85545a',
                        '#9b0012',
                        '#572a31',
                    ],
                    title: {
                        text: data.title || '',
                        align: 'left',
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                        },
                    },
                    xaxis: {
                        type: data.x_axis_datatype,
                        categories: data.labels || [],
                    },
                    yaxis: {
                        title: {
                            text: data.y_axis_label || '',
                        },
                        labels: {
                            formatter: function (val) {
                                if (typeof val == 'number') {
                                    return val.toLocaleString()
                                }
                                return val
                            },
                        },
                    },
                    chart: {
                        stacked: true,
                    },
                    legend: {
                        show: false,
                    },
                },
                type: 'bar',
            },
            histogram: {
                options: {
                    colors: ['#ff5261', '#2c0108'],
                    title: {
                        text: data.title || '',
                        align: 'left',
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '100%',
                        },
                    },
                    xaxis: {
                        type: data.x_axis_datatype,
                        categories: data.labels || [],
                        title: {
                            text: data.x_axis_label || '',
                        },
                    },
                    yaxis: {
                        title: {
                            text: data.y_axis_label || '',
                        },
                        labels: {
                            formatter: function (val) {
                                if (typeof val == 'number') {
                                    return val.toLocaleString()
                                }
                                return val
                            },
                        },
                    },
                    chart: {
                        height: 380,
                        width: '100%',
                        type: 'bar',
                    },
                    stroke: {
                        width: 1,
                        colors: ['#fff'],
                    },
                    legend: {
                        show: false,
                    },
                },
                type: 'bar',
            },
            labeled_line: {
                options: {
                    chart: {
                        height: '75%',
                        width: '100%',
                        animations: {
                            enabled: false,
                        },
                        type: 'line',
                        dropShadow: {
                            enabled: false,
                        },
                        toolbar: {
                            show: true,
                        },
                    },
                    colors: ['#ff5261', '#2c0108'],
                    dataLabels: {
                        enabled: false,
                    },
                    stroke:
                        data.stroke === undefined
                            ? {
                                  curve: 'smooth',
                              }
                            : data.stroke,
                    title: {
                        text: data.title || '',
                        align: 'left',
                    },
                    grid: {
                        borderColor: '#e7e7e7',
                        row: {
                            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.5,
                        },
                    },
                    markers: {
                        size: 0,
                    },
                    xaxis: {
                        type: data.x_axis_type || 'category',
                        categories: xAxisCategories,
                        title: {
                            text: data.x_axis_label,
                        },
                        labels: {
                            format: data.x_axis_date_format || 'MM/dd/y',
                        },
                    },
                    tooltip: {
                        x: {
                            format: data.x_axis_date_format || 'MM/dd/y',
                        },
                    },
                    yaxis:
                        data.yaxis === undefined
                            ? {
                                  title: {
                                      text: data.y_axis_label,
                                  },
                                  forceNiceScale: true,
                              }
                            : data.yaxis,
                    legend:
                        data.legend === undefined
                            ? {
                                  position: 'top',
                                  horizontalAlign: 'center',
                                  floating: true,
                                  offsetY: -25,
                                  offsetX: -5,
                              }
                            : data.legend,
                },
                type: 'line',
            },
            scatter: {
                options: {
                    chart: {
                        height: '75%',
                        type: 'scatter',
                        zoom: {
                            enabled: false,
                        },
                    },
                    title: {
                        text: data.title || '',
                        align: 'left',
                    },
                    xaxis: {
                        tickAmount: 10,
                        labels: {
                            formatter: function (val) {
                                return parseFloat(val).toFixed(1)
                            },
                        },
                    },
                    yaxis: {
                        tickAmount: 10,
                        labels: {
                            formatter: function (val) {
                                return parseFloat(val).toFixed(1)
                            },
                        },
                    },
                },
                type: 'scatter',
            },
            bubble_chart: {
                options: {
                    colors: [
                        '#ff5261',
                        '#2c0108',
                        '#9b0012',
                        '#d50037',
                        '#85545a',
                        '#572a31',
                    ],
                    plotOptions: {
                        bubble: {
                            zScaling: true,
                            minBubbleRadius: 2,
                            maxBubbleRadius: 50,
                        },
                    },
                    chart: {
                        type: 'bubble',
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    fill: {
                        opacity: 0.8,
                    },
                    title: {
                        text: data.title || '',
                        align: 'left',
                    },
                    xaxis: {
                        type: data.x_axis_datatype,
                        title: {
                            text: data.x_axis_label,
                        },
                    },
                    yaxis: {
                        title: {
                            text: data.y_axis_label,
                        },
                    },
                    tooltip: {
                        custom: function ({
                            series,
                            seriesIndex,
                            dataPointIndex,
                            w,
                        }) {
                            var data =
                                w.globals.initialSeries[seriesIndex].data[
                                    dataPointIndex
                                ]

                            var tooltipHTML =
                                '<ul>' +
                                '<li><b>' +
                                data.x_tooltip_label +
                                '</b>: ' +
                                data.x +
                                '</li>' +
                                '<li><b>' +
                                data.y_tooltip_label +
                                '</b>: ' +
                                data.y +
                                '</li>' +
                                '<li><b>' +
                                data.z_tooltip_label +
                                '</b>: ' +
                                data.z +
                                '</li>'

                            for (const [k, v] of Object.entries(data)) {
                                if (
                                    [
                                        'x_tooltip_label',
                                        'y_tooltip_label',
                                        'z_tooltip_label',
                                        'x',
                                        'y',
                                        'z',
                                    ].includes(k)
                                )
                                    continue

                                tooltipHTML =
                                    tooltipHTML +
                                    '<li><b>' +
                                    k +
                                    '</b>: ' +
                                    v +
                                    '</li>'
                            }

                            tooltipHTML = tooltipHTML + '</ul>'

                            return tooltipHTML
                        },
                    },
                },
                type: 'bubble',
            },
            stacked_bar_area: {
                options: {
                    chart: {
                        height: '75%',
                        width: '100%',
                        stacked: true,
                        animations: {
                            enabled: false,
                        },
                        type: 'bar',
                        dropShadow: {
                            enabled: false,
                        },
                        toolbar: {
                            show: true,
                        },
                    },
                    colors: [
                        '#ff5261',
                        '#2c0108',
                        '#106e52',
                        '#7ccc14',
                        '#14a1CC',
                        '#1421cc',
                        '#cc14ba',
                        '#ccc314',
                        '#c4f2da',
                        '#090440',
                    ],
                    dataLabels: {
                        enabled: false,
                    },
                    stroke: {
                        curve: 'smooth',
                        width: 1.5,
                    },
                    title: {
                        text: data.title || '',
                        align: 'left',
                    },
                    grid: {
                        borderColor: '#e7e7e7',
                        row: {
                            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.5,
                        },
                    },
                    markers: {
                        size: 0,
                    },
                    xaxis: {
                        categories: xAxisCategories,
                        type: data.x_axis_type || 'category',
                        title: {
                            text: data.x_axis_label,
                        },
                        tickPlacement: 'on',
                        labels: {
                            format: data.x_axis_date_format || 'MM/dd/y',
                        },
                        crosshairs: {
                            show: true, // Enable crosshairs
                            width: 1, // Width of the crosshair line
                            position: 'back', // Position: 'back' or 'front'
                            opacity: 0.9,
                            stroke: {
                                color: '#b6b6b6', // Color of the crosshair
                                width: 1,
                                dashArray: 0, // Dotted or solid line
                            },
                        },
                        tooltip: {
                            enabled: true,
                            formatter: function (val, opts) {
                                return moment
                                    .utc(val)
                                    .format('YYYY-MM-DD HH:mm')
                            },
                        },
                    },
                    yaxis: {
                        title: {
                            text: data.y_axis_label,
                        },
                        forceNiceScale: true,
                    },
                    legend: {
                        position: 'left',
                        floating: false,
                        offsetY: 30,
                    },
                    fill: {
                        type: 'solid',
                    },
                    plotOptions: {
                        bar: {
                            columnWidth: '100%',
                        },
                    },
                },
                type: 'bar',
            },
            area_basic: {
                options: {
                    chart: {
                        height: '75%',
                        width: '100%',
                        animations: {
                            enabled: false,
                        },
                        type: 'area',
                        dropShadow: {
                            enabled: false,
                        },
                        toolbar: {
                            show: true,
                        },
                    },
                    colors: [
                        '#ff5261',
                        '#2c0108',
                        '#9c6893',
                        '#7ccc14',
                        '#14a1CC',
                        '#1421cc',
                        '#cc14ba',
                        '#ccc314',
                        '#c4f2da',
                        '#090440',
                    ],
                    dataLabels: {
                        enabled: false,
                    },
                    stroke: {
                        curve: 'smooth',
                        width:
                            data.stroke === undefined
                                ? 2
                                : data.stroke.width || 2,
                    },
                    title: {
                        text: data.title || '',
                        align: 'left',
                    },
                    grid: {
                        borderColor: '#e7e7e7',
                        row: {
                            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.5,
                        },
                    },
                    markers: {
                        size: 0,
                    },
                    xaxis: {
                        categories: xAxisCategories,
                        type: data.x_axis_type || 'category',
                        title: {
                            text: data.x_axis_label,
                        },
                        labels: {
                            format: data.x_axis_date_format || 'MM/dd/y',
                        },
                        tooltip: {
                            formatter: function (val, opts) {
                                return moment
                                    .utc(val)
                                    .format('YYYY-MM-DD HH:mm')
                            },
                        },
                    },
                    fill: {
                        type:
                            data.fill === undefined
                                ? 'solid'
                                : data.fill.type || 'solid',
                        opacity:
                            data.fill === undefined
                                ? 0.9
                                : data.fill.opacity || 0.9,
                    },
                    yaxis: {
                        title: {
                            text: data.y_axis_label,
                        },
                        forceNiceScale: true,
                    },
                    legend: {
                        position: 'left',
                        floating: false,
                        offsetY: 30,
                    },
                },
                type: 'area',
            },
        }

        if (tooltip !== undefined && tooltip !== null) {
            CHART_DEFAULTS[type].options.tooltip = tooltip
        }

        return (
            <Chart
                options={CHART_DEFAULTS[type].options}
                series={data.series}
                type={CHART_DEFAULTS[type].type}
                width="75%"
            />
        )
    }

    const CUSTOM_CHARTS = {
        mim_analysis_by_path: ({ props }) => <MiMAnalysisByPath {...props} />,
        mim_raw_dam_analysis: ({ props }) => <MiMRawDamAnalysis {...props} />,
        mim_raw_crr_analysis: ({ props }) => <MiMRawCRRAnalysis {...props} />,
        data_table: ({ props }) => <DataTable {...props} />,
        dam3_flow_decomposition_driver: ({ props }) => (
            <DAM3FlowDecompositionDriver {...props} />
        ),
        dam3_fd_on_demand_starter: ({ props }) => (
            <DAM3FDOnDemandStarter {...props} />
        ),
    }

    return (
        <div style={{ margin: '0 auto', width: '100%' }}>
            {CUSTOM_CHARTS[type]
                ? CUSTOM_CHARTS[type]({ props: props })
                : getChart()}
        </div>
    )
}

export default TaskChart
