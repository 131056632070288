// Function to add IDs based on index if row doesn't have an ID
export const addIdsToRows = (rows) => {
    return rows.map((row, index) => ({
        ...row,
        id: row.id || index + 1, // If row already has an ID, use it, otherwise generate based on index
    }))
}

// Function to convert camelcase strings to title (spaces and uppercase first letter)
export const camel2title = (camelCase) =>
    camelCase
        .replace(/([A-Z])/g, (match) => ` ${match}`)
        .replace(/^./, (match) => match.toUpperCase())
        .trim()

// Function to generate columns dynamically based on the data
export const generateColumns = (data, columnWidths = []) => {
    const columns = Object.keys(data[0]).map((key, index) => ({
        field: key,
        headerName: camel2title(key),
        width: columnWidths[index] || 180, // Use specified width or default to 180
        editable: false, // Set editable to false for all columns
    }))

    return columns
}

// Same as generateColumns but doesn't parse column names, just shows them as they are
export const generateColumnsNoCamelToTitle = (data) => {
    const columns = Object.keys(data[0]).map((key, index) => ({
        field: key,
        headerName: key,
        width: index === 0 ? 310 : 170, // Use double width for the first column
        editable: false, // Set editable to false for all columns
        hide: key === 'id' ? true : false, // Auto hides the id column
    }))

    return columns
}

export const generateColumnsWithCustomColors = (
    data,
    column_colors,
    classes
) => {
    // eslint-disable-next-line
    if (column_colors == []) {
        return generateColumnsNoCamelToTitle(data)
    }
    // Choose a color for each group
    let chosen_colors = {}
    for (let i = 0; i < column_colors.length; i++) {
        let color_group = column_colors[i]
        for (let j = 0; j < color_group.length; j++) {
            let column_name = color_group[j]
            chosen_colors[column_name] = 'dataTableColumnColor' + i
        }
    }
    const columns = Object.keys(data[0]).map((key, index) => ({
        field: key,
        headerName: key,
        width: index === 0 ? 310 : 170, // Use double width for the first column
        editable: false, // Set editable to false for all columns
        hide: key === 'id' ? true : false, // Auto hides the id column
        headerClassName:
            key in chosen_colors ? classes[chosen_colors[key]] : '',
        cellClassName: key in chosen_colors ? classes[chosen_colors[key]] : '',
    }))
    return columns
}

// Reorders object columns into specified order
export const preferredOrder = (obj, order) => {
    var newObject = {}
    for (var i = 0; i < order.length; i++) {
        if (obj.hasOwnProperty(order[i])) {
            newObject[order[i]] = obj[order[i]]
        }
    }
    return newObject
}

// Function that adds formatting commas to numbers (for instance turns 1000 into "1,000")
// for each element in the input data, which is an array of objects (rows of the data table)
export const addCommasToNumbers = (data) => {
    let out_data = []
    for (let i = 0; i < data.length; i++) {
        let row = data[i]
        for (let key in row) {
            if (typeof row[key] === 'number') {
                row[key] = row[key].toLocaleString()
            }
        }
        out_data.push(row)
    }
    return out_data
}
