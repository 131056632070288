import YearSelector from '../CustomWidgets/YearSelector'
import NodeSearch from '../CustomWidgets/NodeSearch'
import IntervalSplitDate from '../CustomWidgets/IntervalSplitDate'
import DataTransformationSearch from '../CustomWidgets/DataTransformationSearch'
import DataExtractionSearch from '../CustomWidgets/DataExtractionSearch'
import FeasibilityTestModelSearch from '../CustomWidgets/FeasibilityTestModelSearch'
import SearchDAMSlackBusName from '../CustomWidgets/SearchDAMSlackBusName'
import SlackBusNameAuctionSearch from '../CustomWidgets/SlackBusNameAuctionSearch'
import DAMPostProcessingScenarioWidget from '../CustomWidgets/DAMPostProccessingScenarioWidget'
import DAMPostProcessingAuctionTypeWidget from '../CustomWidgets/DAMPostProcessingAuctionTypeWidget'
import DAMPostProcessingAuctionMonthAndYearWidget from '../CustomWidgets/DAMPostProcessingAuctionMonthAndYearWidget'
import AuctionIIPipelineSearch from '../CustomWidgets/AuctionIIPipelineSearch'
import DAMPostProcessingPipelineSearch from '../CustomWidgets/DAMPostProcessingPipelineSearch'
import DAMPostProcessingPipelineSearchWithoutABCCutoff from '../CustomWidgets/DAMPostProcessingPipelineSearchWithoutABCCutoff'
import DAMPostProcessingSingleScenarioWidget from '../CustomWidgets/DAMPostProcessingSingleScenarioWidget'
import DAMPostProcessingContingenciesWidget from '../CustomWidgets/DAMPostProcessingContingenciesWidget'
import DAMPostProcessingContingencyBranchesWidget from '../CustomWidgets/DAMPostProcessingContingencyBranchesWidget'
import PortfolioStatsAuctionTypeWidget from '../CustomWidgets/PortfolioStatsAuctionTypeWidget'
import PortfolioStatsAuctionMonthAndYearWidget from '../CustomWidgets/PortfolioStatsAuctionMonthAndYearWidget'
import AuctionModelPipelineSearch from '../CustomWidgets/AuctionModelPipelineSearch'
import AuctionModelPipelineRoundSelect from '../CustomWidgets/AuctionModelPipelineRoundSelect'
import AuctionModelPipelineSelect from '../CustomWidgets/AuctionModelPipelineSelect'
import MMDPScenarioWidget from '../CustomWidgets/MMDPScenarioWidget'
import MMDPSearch from '../CustomWidgets/MMDPSearch'
import MNOSearch from '../CustomWidgets/MNOSearch'
import TUXSearch from '../CustomWidgets/TUXSearch'
import CAMSearch from '../CustomWidgets/CAMSearch'
import NetworkModelTimestampSelect from '../CustomWidgets/NetworkModelTimestampSelect'

const widgets = {
    yearSelector: YearSelector,
    nodeSearch: NodeSearch,
    intervalSplitDate: IntervalSplitDate,
    dataTransformationSearch: DataTransformationSearch,
    dataExtractionSearch: DataExtractionSearch,
    feasibilityTestModelSearch: FeasibilityTestModelSearch,
    searchDAMSlackBusName: SearchDAMSlackBusName,
    slackBusNameAuctionSearch: SlackBusNameAuctionSearch,
    damPostProcessingScenarioWidget: DAMPostProcessingScenarioWidget,
    damPostProcessingAuctionTypeWidget: DAMPostProcessingAuctionTypeWidget,
    damPostProcessingAuctionMonthAndYearWidget:
        DAMPostProcessingAuctionMonthAndYearWidget,
    auctionIIPipelineSearch: AuctionIIPipelineSearch,
    damPostProcessingPipelineSearch: DAMPostProcessingPipelineSearch,
    DAMPostProcessingPipelineSearchWithoutABCCutoff:
        DAMPostProcessingPipelineSearchWithoutABCCutoff,
    DAMPostProcessingSingleScenarioWidget:
        DAMPostProcessingSingleScenarioWidget,
    DAMPostProcessingContingencyBranchesWidget:
        DAMPostProcessingContingencyBranchesWidget,
    DAMPostProcessingContingenciesWidget: DAMPostProcessingContingenciesWidget,
    portfolioStatsAuctionTypeWidget: PortfolioStatsAuctionTypeWidget,
    portfolioStatsAuctionMonthAndYearWidget:
        PortfolioStatsAuctionMonthAndYearWidget,
    auctionModelPipelineSearch: AuctionModelPipelineSearch,
    auctionModelPipelineRoundSelect: AuctionModelPipelineRoundSelect,
    auctionModelPipelineSelect: AuctionModelPipelineSelect,
    mmdpScenarioWidget: MMDPScenarioWidget,
    mmdpSearch: MMDPSearch,
    mnoSearch: MNOSearch,
    tuxSearch: TUXSearch,
    camSearch: CAMSearch,
    networkModelTimestampSelect: NetworkModelTimestampSelect,
}
export default widgets
